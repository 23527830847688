<template>
  <div id="weather">
    <div id="weather-title">
      <h1>Ängsö</h1>
      <h2>Uppmätt på <a class="external white" href="https://www.google.com/maps/place/59%C2%B012'09.3%22N+18%C2%B026'25.3%22E/@59.2808088,18.2473524,11z/data=!4m15!1m8!3m7!1s0x0:0x0!2zNTnCsDEyJzA5LjIiTiAxOMKwMjYnMjMuOCJF!3b1!7e2!8m2!3d59.2025503!4d18.4399388!3m5!1s0x0:0x0!7e2!8m2!3d59.2025885!4d18.4403622">norra udden</a><span v-if="data.weather_station">{{`${data.weather_station.last_communication.slice(11,16)}`}}</span></h2>
    </div>



      <div id="temperature">
          <div class="temperature-reading" v-if="data.sensors && data.sensors.UTE1">
              <div class="temperature-large">
                  <span class="bold" v-text="data.sensors.UTE1.temperature"/>°C ute
              </div>
              <div class="temperature-extra">
                  <div class="temperature-small">
                      <span class="">BATTERI: </span>
                      <span class="info bold"  v-text="data.sensors.UTE1.battery_level"></span>
                  </div>
                  <div class="temperature-small">
                      <span class="">SIGNAL: </span>
                      <span class="info bold" v-text="data.sensors.UTE1.signal_strength"></span>
                  </div>
                  <div class="temperature-small">
                      <span class="">AVLÄST: </span>
                      <span class="info bold" v-text="`${data.sensors.UTE1.last_communication.slice(11,16)}`"></span>
                  </div>
              </div>
          </div>
          <div class="temperature-reading" v-if="data.sensors &&  data.sensors.VATTEN">
            <div class="temperature-large">
              <span class="bold" v-text="data.sensors.VATTEN.temperature"/>°C i vattnet
            </div>
              <div class="temperature-extra">
              <div class="temperature-small">
                <span class="">BATTERI: </span>
                <span class="info bold"  v-text="data.sensors.VATTEN.battery_level"></span>
              </div>
              <div class="temperature-small">
                <span class="">SIGNAL: </span>
                <span class="info bold" v-text="data.sensors.VATTEN.signal_strength"></span>
              </div>
              <div class="temperature-small">
                <span class="">AVLÄST: </span>
                <span class="info bold" v-text="`${data.sensors.VATTEN.last_communication.slice(11,16)}`"></span>
              </div>
            </div>
          </div>
          <div class="water-footnote">Mätt ca 30cm ner</div>
          <!--

          <div class="temperature-reading" v-if="data.sensors && data.sensors.VATTENHOJD">
              <div class="temperature-large">
                  <span class="bold" v-text="data.sensors.VATTENHOJD.temperature"/>cm vattenstånd
              </div>
              <div class="temperature-extra">
                  <div class="temperature-small">
                      <span class="">BATTERI: </span>
                      <span class="info bold"  v-text="data.sensors.VATTENHOJD.battery_level"></span>
                  </div>
                  <div class="temperature-small">
                      <span class="">SIGNAL: </span>
                      <span class="info bold" v-text="data.sensors.VATTENHOJD.signal_strength"></span>
                  </div>
                  <div class="temperature-small">
                      <span class="">AVLÄST: </span>
                      <span class="info bold" v-text="`${data.sensors.VATTENHOJD.last_communication.slice(11,16)}`"></span>
                  </div>
              </div>
          </div>
          -->
      </div>
      <div id="weather_station">
          <table>
            <tbody>
              <tr>
                  <td class="icon icon_wind"><img src="/assets/noun_Wind_1560880.png" width="40" height="40"/></td>
                  <td class="title">
                    <div class="bold">Vind:</div>
                    <div class="small">Dagens högsta:</div>
                  </td>
                  <td>
                    <div v-if="data.weather_station">
                      <span id="wind_speed" v-text="data.weather_station.wind_speed"/>m/s <span v-text="data.weather_station.wind_dir_swe"></span>
                    </div>
                    <div class="small"  v-if="data.weather_station">
                      <span v-text="`${Math.round(data.weather_station.wind_day_high)}`"/>m/s kl <span v-text="data.weather_station.wind_day_high_time"/>
                    </div>
                      <div class="small"  v-if="data.weather_station">
                        Vinden mäts på Bivalds brygga (<a class="external white" href="https://www.google.com/maps/place/59%C2%B012'09.9%22N+18%C2%B026'25.5%22E/@59.202762,18.4398675,188m/data=!3m2!1e3!4b1!4m15!1m8!3m7!1s0x0:0x0!2zNTnCsDEyJzA5LjIiTiAxOMKwMjYnMjMuOCJF!3b1!7e2!8m2!3d59.2025503!4d18.4399388!3m5!1s0x0:0x0!7e2!8m2!3d59.2027621!4d18.4404158">karta</a>)
                      </div>
                  </td>
              </tr>
              <tr>
                  <td class="icon"><img src="/assets/noun_Rain_882266.png" width="40" height="40"/></td>
                  <td class="title">
                    <div class="bold">Regn:</div>
                    <div class="small">Regn just nu:</div>
                  </td>
                  <td>
                    <div v-if="data.weather_station">
                      {{data.weather_station.rain_day_mm}}mm idag
                    </div>
                    <div class="small"  v-if="data.weather_station">
                      {{data.weather_station.rain_rate_mm_per_hr}}mm/timme
                    </div>
                  </td>
              </tr>

              <tr>
                <td class="icon"><img src="/assets/noun_Barometer_217491.png" width="40" height="40"/></td>
                <td class="title">
                  <div class="bold">Barometer:</div>
                  <div class="small">Barometer trend:</div>
                </td>
                <td>
                  <div  v-if="data.weather_station">
                    <span  v-text="data.weather_station.pressure"/>hP
                  </div>
                  <div  v-if="data.weather_station" class="small">
                    <span  v-text="data.weather_station.pressure_tendancy"/>
                  </div>
                </td>
              </tr>

              <tr>
                <td class="icon"><img src="/assets/noun_sunrise_2125579.png" width="40" height="40"/></td>
                <td class="title">
                  <div class="bold">Soluppgång:</div>
                  <div class="small">Solnedgång:</div>
                </td>
                <td>
                  <div>
                    <span  v-if="data.weather_station" v-text="data.weather_station.sunrise"/>
                  </div>
                  <div class="small">
                    <span  v-if="data.weather_station" v-text="data.weather_station.sunset"/>
                  </div>
                </td>
              </tr>

              <tr v-if="data.water_level">
                  <td class="icon"><img src=""/></td>
                  <td class="bold title">Vattenstånd:</td>
                  <td><span v-text="data.water_level"/></td>
              </tr>
            </tbody>
          </table>
      </div>
  </div>
</template>

<script>
export default {
  name: 'Weather',
  props: {
    data: Object
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bold {
    font-weight: 700;
}

.temperature-reading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.temperature-large {
  font-size: 1.5rem;
}

.temperature-small {
  font-size: 0.7rem;
}

#weather {
  padding: 10px;
  background: #1d384b;
  color: white;
}

#weather a {
  color: white;
}

#weather #temperature {
  font-family: "Open Sans", Helvetica, Arial;
  background: #3E5462;
  padding: 10px;
  border-top: 2px solid rgba(224, 228, 231, 0.25);
  border-bottom: 2px solid rgba(224, 228, 231, 0.25);
}

#weather-title {
  display: flex;
  justify-content: space-between;
}

h1 {
  font-weight: bold;
  padding: 0;
  margin: 0;
  font-family: 'Merriweather', serif;
}

h2 {
  font-weight: 400;
  font-size: 1rem;
  text-align: right;
  font-family: "Open Sans", Helvetica, Arial;
}

  #weather_station {
    font-family: "Open Sans", Helvetica, Arial;
    width: 100%;
  }

  #weather_station table {
    table-layout: fixed;
    width: 100%;
  }
  #weather_station td {

    padding-top: 5px;
    padding-bottom: 5px;
    vertical-align: top;
  }

  #weather_station td.icon {
    width: 50px;
      padding: 0;
      text-align: center;
      vertical-align: bottom;
  }

  #weather_station td.icon_wind {
    width: 50px;
      padding: 0;
      padding-top: 5px;
      text-align: center;
      vertical-align: top;
  }

#weather_station td.title {
  width: 120px;
}
  #weather_station tr {
   border-bottom: 1px solid rgba(224, 228, 231, 0.25);
    width: 100%;
  }

#weather_station tr:last-child {
  border-bottom: 0px solid rgba(224, 228, 231, 0.25);
}

#weather-title a {
    text-decoration: none;
}

  .small {
    font-size: 0.8rem;
  }

    .temperature-reading {
        margin-bottom: 20px;
    }

</style>
