<template>
  <div id="app">
    <Weather :data="data" />
    <div class="footnote">
      <a class="external"  target="_blank" href="https://www.google.com/maps/place/59%C2%B012'09.9%22N+18%C2%B026'25.5%22E/@59.2027467,18.4398698,243m/data=!3m2!1e3!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d59.2027465!4d18.4404174">Vindmätarplacering</a>
    </div>
    <Camera :data="data" />
    <Forecast :data="data" />
      <footer>
          <h4>Vad är vädretpålandet?</h4>
          <p>Denna sidan presenterar vädret på ön Ängsö utanför Tyresö Brevik (inte nationalparken). Vill du ha mail när vi lägger till nya funktioner? Skicka din e-postadress till <a href="mailto:niklas@bivald.com" class="external">niklas@bivald.com</a></p>
          <p>Sidan drivs av familjen Bivald</p>
      </footer>
  </div>
</template>

<script>
import Weather from './components/Weather.vue'
import Camera from './components/Camera.vue'
import Forecast from './components/Forecast.vue'

export default {
  name: 'App',
  components: {
    Weather,
    Camera,
    Forecast
  },
  data() {
      return {
        data: {}
      }
    },
    beforeMount(){
      this.getData();
    },
    methods: {
      async getData(){
          const res = await fetch('https://api.vadretpalandet.se/affeb19f-688c-42d0-9453-cb62650b3313.js');
          const data = await res.json();
          //const data = JSON.parse('{"datetime": 1596113207506.0, "sensors": {"UTE1": {"name": "UTE1", "temperature": 26.5, "signal_strength": 64.0, "battery_level": 100.0, "last_communication": "2020-07-21 17:50:56"}, "INNE": {"name": "INNE", "temperature": 26.6, "signal_strength": 85.0, "battery_level": 100.0, "last_communication": "2020-07-30 12:40:06"}, "UTE2": {"name": "UTE2", "temperature": 21.0, "signal_strength": 82.0, "battery_level": 100.0, "last_communication": "2020-07-30 12:09:14"}}, "camera2": "2020-07-30_124641-camera2.jpg", "camera1": "2020-07-30_124641-camera1.jpg", "weather_station": {"sunrise": "06:11", "dewpoint": 12.2, "rain_rate_year_high_mm_per_hr": 160.78199999999998, "rain_storm_mm": 2.2859999999999996, "wind_day_high_time": "01:14", "rain_rate_day_high_mm_per_hh": 22.098, "wind_day_high": 10.281920000000012, "pressure": 1003.4, "wind_dir": "Northeast", "rain_rate_hour_high_mm_per_hh": 0.0, "rain_rate_month_high_mm_per_hr": 160.78199999999998, "rain_day_mm": 2.3, "heat_index": 20.0, "rain_rate_mm_per_hr": 0.0, "wind_dir_swe": "Ostnordost", "wind_degrees": 37.0, "sunset": "08:18", "temperature": 20.1, "pressure_tendancy": "Steady", "wind_speed": "28.8", "rain_storm_start_date": "2020-07-14", "windchill": "17.2", "last_communication": "2020-07-30 14:45:47"}, "id": "sensordata"}');
          data['camera1'] = 'https://image.vadretpalandet.se/file/vadretpalandet/' + data['camera1'];
          data['camera2'] = 'https://image.vadretpalandet.se/file/vadretpalandet/' + data['camera2'];

          this.data = data;
      }
    }
}
</script>

<style>
body,html {
  margin: 0;
  padding: 0;
  width: 100%;
}

#app {
  width: 100%;
  margin: 0 auto;
  max-width: 800px;
}

.footnote {
  padding: 3px;
  text-align: right;
  font-size: 1rem;
}

a.external {
    background: url('/assets/noun_exit_link_2006660.png') center right no-repeat;
    background-size: 16px 16px;
    padding-right: 16px;
}

a.external:hover {
    color: black;
}

a.external.white {
    background: url('/assets/noun_exit_link_2006660-white.png') center right no-repeat;
    background-size: 16px 16px;
    padding-right: 16px;
    margin-right: 5px;
}

a.external.white:hover {
    color: white;
}


.footnote a {
    color: #333333;
    text-decoration: none;
}

table {
    border-collapse: collapse;
}

    footer {
        box-sizing: border-box;
        padding: 10px;
    }

    footer h4 {
        font-family: 'Merriweather', serif;
        margin-bottom: 0px;
    }

    footer a {
        color: inherit;
    }

    footer p {
        margin-top: 0px;

        font-family: "Open Sans", serif;
    }
</style>
