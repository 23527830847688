<template>
  <div id="forecast">
      <h3>Femdygnsprognos</h3>
      <h4>Hämtat från <a class="external white" href="https://www.yr.no/">yr.no</a></h4>
      <table v-if="forecasts" id="forecasts">
          <thead>
              <tr>
                  <th class="time">Datum/Tid</th>
                  <th class="weather">Väder</th>
                  <th class="rain">Regn</th>
                  <th class="temperature">°C</th>
                  <th class="wind">Vind</th>
              </tr>
          </thead>
        <tr v-for="forecast in forecasts" :key="forecast.index">
            <td class="time"><span v-text="forecast.datetime"></span></td>
            <td class="weather"><img :src="`/assets/weather/${forecast.symbol_code}.png`"></td>
            <td class="rain"><span v-text="forecast.precipitation_amount"></span></td>
            <td class="temperature"><span v-text="forecast.air_temperature"></span></td>
            <td class="wind"><span>{{forecast.wind_from_direction|wind_dir}}<br>{{forecast.wind_speed}} m/s</span></td>
        </tr>
      </table>
  </div>
</template>

<script>
const NAMES = [
    'Nord',
    'Nordnordost',
    'Nordost',
    'Ostnordost',
    'Öst',
    'Ostsydost',
    'Sydost',
    'Sydsydost',
    'Syd',
    'Sydsydväst',
    'Sydväst',
    'Västsydväst',
    'Väst',
    'Västnordväst',
    'Nordväst',
    'Nordnordväst',
    'Nord'
];

export default {
  name: 'Forecast',
  props: {
    data: Object,
  },
    filters: {
        wind_dir: function (wd) {
            if (!wd) return '';
            let index = wd % 360;
            index = Math.round(index/ 22.5,0);
            return NAMES[parseInt(index)];
        }
    },
    data() {
        return {
            forecasts: {}
        }
    },
    beforeMount(){
        this.getData();
    },
    methods: {
        async getData(){
            const res = await fetch('https://api.met.no/weatherapi/locationforecast/2.0/complete?lat=59.20235&lon=18.44061&altitude=4');
            this.forecasts = [];
            const hourly_forecasts = await res.json();

            console.log(hourly_forecasts['properties']['timeseries'])
            const hours = [0,6,14,12,18];
            const options = {weekday: 'long', hour: 'numeric', minute: 'numeric' };
            for (let index in hourly_forecasts['properties']['timeseries']) {
                const forecast = hourly_forecasts['properties']['timeseries'][index];
                const date = new Date(forecast['time']);
                if (hours.includes(date.getHours())) {
                    let forecast_data = forecast['data']['instant']['details'];
                                           console.log(forecast_data['symbol_code'] = forecast['data'])
                    forecast_data['datetime'] = date.toLocaleString('sv-SE', options)
                    if(forecast['data']['next_1_hours']) {
                        forecast_data['symbol_code'] = forecast['data']['next_1_hours']['summary']['symbol_code'];
                        forecast_data['precipitation_amount'] = forecast['data']['next_1_hours']['details']['precipitation_amount'];
                    }
                    if(forecast['data']['next_6_hours']) {
                        forecast_data['symbol_code'] = forecast['data']['next_6_hours']['summary']['symbol_code'];
                        forecast_data['precipitation_amount'] = forecast['data']['next_6_hours']['details']['precipitation_amount'];
                    }
                    this.forecasts.push(
                        forecast_data
                    )
                }
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    h3 {
        font-family: 'Merriweather', serif;
        padding: 0;
        margin: 0;
        font-size: 2rem;
        margin-bottom: 10px;
    }

    h4 {
        font-size: 0.8rem;
        font-weight: 400;
        margin: 0px;
        padding: 0;
        margin-bottom: 10px;
    }

    h4 a {
        color: white;
        text-decoration: none;
    }


    #forecast {
        font-family: "Open Sans", Helvetica, Arial;
        width: 100%;
        background: #1d384b;
        color: white;

        box-sizing: border-box;
        padding: 10px;
    }

    #forecasts table {
        table-layout: fixed;
        width: 100%;
    }
    #forecasts td,th {
        padding-top: 5px;
        padding-bottom: 10px;
        vertical-align: top;
        text-align: left;
    }

    #forecasts td {

    }

    #forecasts tr {
        border-bottom: 1px solid rgba(224, 228, 231, 0.25);
        height: 60px;
    }

    #forecasts thead tr {
        height: auto;
    }

    #forecasts tr:last-child {
        border-bottom: 0;
    }

    #forecasts td.time {
        padding: 0;
        width: 150px;

    }

    #forecasts td.time {
        text-transform:capitalize;
    }

    #forecasts td.weather {
        width: 70px;
        vertical-align: middle;
    }

    #forecasts .rain {
        width: 70px;
    }
    #forecasts .temperature {
        width: 70px;
    }

    #forecasts .rain {

    }

    #forecasts td {
        padding-top: 5px;
        vertical-align: middle

    }

    #forecasts img  {
        height: 30px;
        width: 30px;
    }
</style>
