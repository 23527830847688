<template>
    <div id="camera">
        <div class="camera">
            <h3>Kamera 1: Husnocken</h3>
            <h4><a class="external" target="_blank" href="https://www.google.com/maps/place/59%C2%B012'08.5%22N+18%C2%B026'26.2%22E/@59.2023557,18.4400708,243m/data=!3m2!1e3!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d59.2023546!4d18.4406182">59.202355, 18.440618</a></h4>
            <span id="camera1">
                <a :href="data.camera1"><img :src="data.camera1"></a>
            </span>
        </div>
        <div class="camera">
            <h3>Kamera 2: Bryggan</h3>
            <h4><a class="external" target="_blank" href="https://www.google.com/maps/place/59%C2%B012'09.9%22N+18%C2%B026'25.5%22E/@59.2027497,18.4398748,243m/data=!3m2!1e3!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d59.2027491!4d18.4404222">59.202749, 18.440422</a></h4>
            <span id="camera2">
                <a :href="data.camera2"><img :src="data.camera2"></a>
            </span>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Camera',
  props: {
    data: Object
  }
}
</script>

<style scoped>
.camera img {
    width: 100%;
    height: auto;
}
h3,h4 {
    font-family: "Open Sans", Helvetica, Arial;
}

h3 {
    padding-bottom: 0px;
    margin-bottom: 0px;
}

h4 {
    padding-top: 0px;
    margin-top: 0px;
    font-size: 0.8rem;
    font-weight: 400;
}

    a {
        color: #333333;
        text-decoration: none;
    }

    hr {
        background: #999;
        height: 1px;
        border: 0px;
    }

#camera {
    margin-bottom: 50px;
}

</style>
